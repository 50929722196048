<template>
  <moe-page title="供应商账单">
    <moe-card class="mb-20" :autoHeight="true">
      <template slot="header">
        <div class="font-20 fwb">供应商账单</div>
      </template>

      <!-- 筛选信息 -->
      <moe-inquire @search="billSearch">
        <el-form-item label="输入供应商名称">
          <el-input v-model.trim="billParams.name" placeholder="输入供应商名称" maxlength="50" clearable />
        </el-form-item>
        <el-form-item label="供应商ID">
          <el-input v-model.trim="billParams.id" placeholder="输入供应商ID" maxlength="50" clearable />
        </el-form-item>
        <el-form-item label="创建时间" prop="startTime">
          <el-date-picker
            style="width: 100%;"
            placement="bottom-start"
            v-model="datetime"
            type="monthrange"
            value-format="yyyy-MM"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            @change="(datetime) => ([billParams.startTime, billParams.endTime] = datetime || ['', ''])" />
        </el-form-item>
      </moe-inquire>

      <moe-table ref="billTableRef" url="/shop/vendor/billList" :params="billParams" :numberShow="false">
        <el-table-column label="单据编码" prop="no" min-width="150" :show-overflow-tooltip="true" />
        <el-table-column label="供应商名称" prop="name" min-width="150" :show-overflow-tooltip="true" />
        <el-table-column label="销售金额" prop="amount" min-width="150" />
        <el-table-column label="运费" prop="freight" min-width="150" />
        <el-table-column label="时间" min-width="150" :formatter="formatterTime"/>
        <el-table-column label="操作" fixed="right" min-width="150">
          <div class="moe-table_btns" slot-scope="{ row }">
            <el-button type="success" size="mini" icon="el-icon-document" @click="$moe_coordinator.navigateTo(`/billingManage/supplierBilling/detail`, { no: row.no })">详情</el-button>
          </div>
        </el-table-column>
      </moe-table>
    </moe-card>
  </moe-page>
</template>

<script>
export default {
  name: '',
  data() {
    return {
      billParams: {
        pageNum: 1,
        pageSize: 10,
        id: '',
        name: '',
        startTime: '',
        endTime: '',
      },
      datetime: [],
    }
  },
  methods: {
    billSearch(isSearch) {
      if (!isSearch) {
        this.billParams = {
          pageNum: 1,
          pageSize: 10,
          id: '',
          name: '',
          startTime: '',
          endTime: '',
        }
        this.datetime = [];
      }

      this.$refs['billTableRef'].searchData();
    },
    formatterTime({ time }) {
      return this.$moe_time.getTimeStamp(time, 'YYYY年MM月')
    }
  }
}
</script>